import React, { useContext } from 'react'
import StoreContext from '../../../context/StoreContext'
import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid2'

const Line = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #D6D4D0;
  padding: 0;
`

const RemoveButton = styled.span`
  font-style: italic;
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  color: ${props => props.theme.text_color};
  cursor: pointer;
`

const PreOrder = styled.div`
  margin-top: 1rem;
  font-style: italic;
`

const MobilePrice = styled.div`
  text-align: right;
`

const MobileProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Amount = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  &:first-child {
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
  cursor: pointer;
`

const ProductLine = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  &.right {
    justify-content: flex-end;
  }
`

const Description = styled.div`
  margin-top: 1rem;
`
const RemoveLink = styled.div`
  text-align: right;
  width: 100%;
  padding-right: 10px;
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  .variant-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  @media (max-width: 768px) {
    display: block;
  }
`

const MobileTitle = styled.span`
  max-width: 70%;
`

const LineItem = props => {

  // const [quantity, setQuantity] = useState(line_item.quantity)
  const context = useContext(StoreContext)
  const { line_item } = props
  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      height="200px"
    />
  ) : null

  const handleRemove = () => {
    context.removeLineItem(context.client, context.checkout.id, line_item.id)
  }

  const handleQuantityChange = op => {
    if(op === 'inc') {
      const newQuantity = line_item.quantity+1; // inc
      context.updateLineItem(context.client, context.checkout.id, line_item.id, newQuantity);
    } else if(op === 'dec' && line_item.quantity !== 0) {
      const newQuantity = line_item.quantity-1; // dec
      context.updateLineItem(context.client, context.checkout.id, line_item.id, newQuantity);
    } else if(op === 'dec' && line_item.quantity === 0) { // remove if
      context.removeLineItem(context.client, context.checkout.id, line_item.id)
    }
  }

  const size = line_item.variant.title === 'Default Title' ? '' : 'Size: '+line_item.variant.title

  const hasPreorder = line_item.variant.sku.toLowerCase() === 'preorder' ? true : false;

  return (
      <>

      <Desktop>
        <Row>
          <Col xl="3" lg="3" md="12" sm="12">{variantImage}</Col>
          <Col xl="3" lg="3" md="12" sm="12">
            <ProductLine>
              <div>{line_item.title}
              { size &&
                <Description>{size}</Description>
              }
              { hasPreorder &&
                <PreOrder>· Pre-order item</PreOrder>
              }
              </div>
            </ProductLine></Col>
          <Col xl="2" lg="2" md="12" sm="12"><ProductLine><Amount onClick={() =>handleQuantityChange('inc')}>+</Amount> {line_item.quantity} <Amount onClick={() => handleQuantityChange('dec')}>-</Amount></ProductLine></Col>
          <Col xl="2" lg="2" md="12" sm="12"><ProductLine>£{line_item.variant.price}</ProductLine></Col>
          <Col xl="2" lg="2" md="12" sm="12"><ProductLine className="right"><RemoveButton onClick={handleRemove}>Remove</RemoveButton></ProductLine></Col>
        </Row>
      </Desktop>

      <Mobile>
        <Row>
          <Col md="6" sm="6" xs="5" className="variant-image">{variantImage}</Col>
          <Col md="6" sm="6" xs="7" style={{paddingRight: '10px'}}>
            <MobileProductDetails>
              <MobileTitle>{line_item.title}</MobileTitle><br /><br /><br />
              <div><Amount onClick={() =>handleQuantityChange('inc')}>+</Amount> {line_item.quantity} <Amount onClick={() => handleQuantityChange('dec')}>-</Amount></div>
              <MobilePrice>£{line_item.variant.price}</MobilePrice><br />
            </MobileProductDetails>
          </Col>
        </Row>
        <Row><RemoveLink><RemoveButton onClick={handleRemove}>Remove</RemoveButton></RemoveLink></Row>
      </Mobile>

      <Line />
      </>
  )
}

export default LineItem
