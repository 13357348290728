import React, { useContext } from 'react'
import StoreContext from '../../context/StoreContext'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import LineItem from './LineItem'

const CartContainer = styled.div`
  padding-top: 140px;
  width: 100%;
  @media (max-width: 768px) {
   padding-top: 0px;
  }
`

const EmptyContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    margin-left: -270px;
    text-align: center;
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    a {
      color: black;
      text-decoration: none;
    }
  }
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const Title = styled.h2`
  text-transform: uppercase;
  padding-bottom: 50px;
  font-size: 16px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  &.center {
    text-align: center;
  }
`
const CheckoutButton = styled.button`
  font-family: 'minerva-modern',Garamond,Georgia,'Times New Roman',Times,serif;
  font-size: 13px;
  text-transform: uppercase;
  border: none;
  outline: 0;
  margin-bottom: 0px;
  font-weight: bold;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: #EEE9E2;
    @media (max-width: 768px) {
      background-color: #fff;
    }
  }
  @media (max-width: 768px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`

const Line = styled.hr`
  margin-top: 20px;
  margin-bottom: 0px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #D6D4D0;
  padding: 0;
`

const Left = styled.p`
  text-align: left;
  padding-left: 20px;
  font-style: italic;
  &.r2 {
    padding-top: 1.5rem;
    @media (max-width: 768px) {
      padding-right: 0px;
    }
  }
  @media (max-width: 768px) {
    padding-right: 0px;
  }
  span {
    font-size: 10px;
    font-style: italic;
    color: #9C978F;
  }
`

const Right = styled.p`
  text-align: right;
  &.r1 {
    padding-right: 20px;
    @media (max-width: 768px) {
      padding-right: 0px;
    }
  }
  &.r2 {
    padding-right: 0px;
  }
`

const CheckoutCTA = styled.div`
margin-bottom: 20px;
 & > div {
   padding-left: 0px;
   padding-right: 0px;
 }
  p {
    border-bottom: 1px solid #D6D4D0;
    padding-bottom: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    &:last-child {
      border-bottom: none;
    }
  }
`

const Cart = () => {

  const context = useContext(StoreContext)
  const { checkout } = context

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  let hasPreorder = false;

  const line_items = checkout.lineItems.map(line_item => {
    if(line_item.variant.sku.toLowerCase() === 'preorder') {
      hasPreorder = true;
    }
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  const Loader = checkout.lineItems.length > 0 ? '' : '';

  /*

  <p>Subtotal £{checkout.subtotalPrice}</p>
  <p>Tax £{checkout.totalTax}</p>

*/

  if(checkout.lineItems.length > 0) {
    return (
      <CartContainer>
      <Container fluid>
      <Title>Your Cart</Title>
      <Desktop>
        <Row>
          <Col xl="3" lg="3" md="12" sm="12"></Col>
          <Col xl="3" lg="3" md="12" sm="12"><i>Details</i></Col>
          <Col xl="2" lg="2" md="12" sm="12"><i>Quantity</i></Col>
          <Col xl="2" lg="2" md="12" sm="12"><i>Price</i></Col>
          <Col xl="2" lg="2" md="12" sm="12"></Col>
        </Row>
      </Desktop>
      <Mobile>
        <Row>
          <Col xs="6">Details</Col>
          <Col xs="6" style={{ textAlign: 'right'}}>Price</Col>
        </Row>
      </Mobile>
      <Row>&nbsp;</Row>
      { line_items }
      <Row style={{ padding: '0px'}}>
        <Col xl="5" lg="5" md="5" sm="12" style={{ padding: '0px'}}>
          { hasPreorder &&
            <p>For pre-orders allow 6-8 weeks for delivery. Please be advised we do not<br />offer refunds on pre-orders. Exchange only.</p>
          }
        </Col>
        <Col xl="7" lg="7" md="7" sm="12" style={{ padding: '0px'}}>
          <CheckoutCTA>
            <Container fluid>
              <Row>
                <Col xl="8" lg="8" md="8" sm="8" xs="6" style={{ padding: '0px'}}><Left className="r1">Total</Left></Col>
                <Col xl="4" lg="4" md="4" sm="4" xs="6" style={{ padding: '0px'}}><Right className="r1">£{checkout.totalPrice}</Right></Col>
              </Row>
              <Line />
              <Row>
                <Col xl="7" lg="7" md="7" sm="7" xs="7" style={{ padding: '0px'}}><Left className="r2"><span>Enter your discount code at the checkout</span></Left></Col>
                <Col xl="5" lg="5" md="5" sm="5" xs="5" style={{ padding: '0px'}}><Right className="r2"><CheckoutButton onClick={handleCheckout}>Checkout</CheckoutButton></Right></Col>
              </Row>
            </Container>
          </CheckoutCTA>
        </Col>
      </Row>
      </Container>
      </CartContainer>
    )} else {
      return(
        <EmptyContainer>
          <div>
          <h2>Your bag is currently empty</h2>
          <h3><a href="/">explore collection</a></h3>
          </div>
        </EmptyContainer>
      )
    }
  }

export default Cart
