import React, { Component } from 'react';
import ContainerProduct from '../../components/container_product';
import Cart from '../../components/cart';

class ShoppingCart extends Component {
  render() {
    return (
      <ContainerProduct>
        <Cart />
      </ContainerProduct>
    );
  }
}

export default ShoppingCart;
